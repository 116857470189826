import React, {lazy, Suspense} from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import History from "./History";
import Amplify from 'aws-amplify';
import config from './aws-exports';

Amplify.configure(config);

const HomePage = lazy(() => import('./pages/HomePage'));
const RecipeDetailsPage = lazy(() => import('./pages/RecipeDetailsPage'));
const PublishersPage = lazy(() => import('./pages/PublishersPage'));
const PublisherDetailsPage = lazy(() => import('./pages/PublisherDetailsPage'));
const SearchResultsPage = lazy(() => import('./pages/SearchResultsPage'));
const AboutPage = lazy(() => import('./pages/AboutPage'));
const TermsPage = lazy(() => import('./pages/TermsPage'));
const ContactPage = lazy(() => import('./pages/ContactPage'));
const PrivacyPage = lazy(() => import('./pages/PrivacyPage'));
const CategoriesPage = lazy(() => import('./pages/CategoriesPage'));
const CategoryDetailsPage = lazy(() => import('./pages/CategoryDetailsPage'));
const FaqPage = lazy(() => import('./pages/FaqPage'));
const BadgesPage = lazy(() => import('./pages/BadgesPage'));
const LaunchPage = lazy(() => import('./pages/LaunchPage'));
const PricingPage = lazy( () => import('./pages/PricingPage'));

const renderLoader = () => <p>Loading</p>;

function App(props) {

    return (
      <Router history={History}>
          <Suspense fallback={renderLoader()}>
              {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
              <Switch>
                  <Route exact path="/recipes" render={(routeProps) => <HomePage {...routeProps}/>}/>
                  <Route exact path="/" render={(routeProps) => <LaunchPage {...routeProps}/>}/>
                  <Route path="/recipes/:recipeId" render={(routeProps) => <RecipeDetailsPage {...routeProps}/>}/>
                  <Route path="/search" render={(routeProps) => <SearchResultsPage {...routeProps}/>}/>
                  <Route exact path="/publishers" render={(routeProps) => <PublishersPage {...routeProps}/>}/>
                  <Route path="/publishers/:publisherId" render={(routeProps) => <PublisherDetailsPage {...routeProps}/>}/>
                  <Route exact path="/categories" render={(routeProps) => <CategoriesPage {...routeProps}/>}/>
                  <Route path="/categories/:categoryId" render={(routeProps) => <CategoryDetailsPage {...routeProps}/>}/>
                  <Route path="/about" render={(routeProps) => <AboutPage {...routeProps}/>}/>
                  <Route path="/pricing" render={(routeProps) => <PricingPage {...routeProps}/>}/>
                  <Route path="/badges" render={(routeProps) => <BadgesPage {...routeProps}/>}/>
                  <Route path="/terms" render={(routeProps) => <TermsPage {...routeProps}/>}/>
                  <Route path="/contact" render={(routeProps) => <ContactPage {...routeProps}/>}/>
                  <Route path="/faq" render={(routeProps) => <FaqPage {...routeProps}/>}/>
                  <Route path="/privacy" render={(routeProps) => <PrivacyPage {...routeProps}/>}/>
              </Switch>
          </Suspense>
      </Router>
  );
}

export default App;
