/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:92b7d742-09d2-442f-af44-dba399ca61cc",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_9JalXKflP",
    "aws_user_pools_web_client_id": "2hiunfn1b6h4hu6afkludecvg2",
    "oauth": {},
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
